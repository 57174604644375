import React from "react";
import styled from "styled-components";
import MainTitle from "../shared/heroTitles";
import Paragraph from "../shared/paragraphs";
import PositionContainer from "../shared/container";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../shared/buttons";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .two {
    margin-bottom: 86px;
  }

  .img1 {
    @media (max-width: 960px) {
      width: 325px;
      height: 273px;
    }
  }

  .img2 {
    @media (max-width: 960px) {
      width: 381px;
      height: 250px;
    }
  }

  .img3 {
    @media (max-width: 960px) {
      width: 448px;
      height: 294px;
    }
  }

  .img4 {
    @media (max-width: 960px) {
      width: 292px;
      height: 98px;
    }
  }

  .four {
    @media (max-width: 960px) {
      background: #e1e1e1;
      height: 273px;
    }
  }
`;

const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const SubtitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  margin-top: 30px;
  margin-bottom: 37px;
  @media (max-width: 550px) {
    width: 100%;
  }
`;

const ResourceBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 37px;

  @media (max-width: 960px) {
    flex-direction: column;
    justify-content: center;
    gap: 60px;
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;

  @media (max-width: 960px) {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
`;

const ImgHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e1e1e1;

  @media (max-width: 960px) {
    width: 100%;
    background: transparent;
  }
`;

const TextHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 136px;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

const TextTitle = styled.h3`
  padding: 0px;
  width: 350px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #111111;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
  margin-bottom: 0px;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const ButtonHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-start;
`;

function Hero() {
  return (
    <Container>
      <PositionContainer>
        <ContentHolder>
          <MainTitle features={true}>User Guide to PDF Writer</MainTitle>
          <SubtitleBox>
            <Paragraph type="center">
              The how-to articles below were created to walk you through how our
              most popular tools function. If you need help understanding how a
              feature works, please see below for a handy step-by-step guide.
            </Paragraph>
          </SubtitleBox>
          <ResourceBox>
            <Row>
              <Box>
                <ImgHolder>
                  <StaticImage
                    src="../../images/resourcesPageImages/heroImages/resources_img1.png"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                    alt="Resources Image 1"
                    className="img1"
                  />
                </ImgHolder>
                <TextHolder>
                  <TextTitle>How to Convert PDF to Word</TextTitle>
                  <Paragraph>December 2, 2021</Paragraph>
                  <ButtonHolder>
                    <Button type="blue-empty" title="Learn more"></Button>
                  </ButtonHolder>
                </TextHolder>
              </Box>
              <Box>
                <ImgHolder>
                  <StaticImage
                    src="../../images/resourcesPageImages/heroImages/resources_img2.png"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                    alt="Resources Image 2"
                    className="img2"
                  />
                </ImgHolder>
                <TextHolder>
                  <TextTitle>How to Fill Out a PDF Form</TextTitle>
                  <Paragraph>December 2, 2021</Paragraph>
                  <ButtonHolder>
                    <Button type="blue-empty" title="Learn more"></Button>
                  </ButtonHolder>
                </TextHolder>
              </Box>
            </Row>
            <Row className="two">
              <Box>
                <ImgHolder>
                  <StaticImage
                    src="../../images/resourcesPageImages/heroImages/resources_img3.png"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                    alt="Resources Image 3"
                    className="img3"
                  />
                </ImgHolder>
                <TextHolder>
                  <TextTitle>How to Create PDF files</TextTitle>
                  <Paragraph>December 2, 2021</Paragraph>
                  <ButtonHolder>
                    <Button type="blue-empty" title="Learn more"></Button>
                  </ButtonHolder>
                </TextHolder>
              </Box>
              <Box>
                <ImgHolder className="four">
                  <StaticImage
                    src="../../images/resourcesPageImages/heroImages/resources_img4.png"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                    alt="Resources Image 4"
                    className="img4"
                  />
                </ImgHolder>
                <TextHolder>
                  <TextTitle>How to Fill Out a PDF Form</TextTitle>
                  <Paragraph>December 2, 2021</Paragraph>
                  <Button type="blue-empty" title="Learn more"></Button>
                </TextHolder>
              </Box>
            </Row>
          </ResourceBox>
        </ContentHolder>
      </PositionContainer>
    </Container>
  );
}

export default Hero;
