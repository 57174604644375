import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { navigate } from "gatsby";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { Link } from "gatsby";
const PrimaryButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 24px;
  width: ${({ type }) =>
    type === "primary"
      ? "129px"
      : type === "empty"
      ? "max-content"
      : type === "primary special"
      ? "250px"
      : type === "white"
      ? "max-content"
      : type === "writer"
      ? "220px"
      : type === "white-empty"
      ? "350px"
      : type === "blue"
      ? "147px"
      : type === "visit"
      ? "auto"
      : type === "blue-empty"
      ? "176px"
      : type === "download"
      ? "175px"
      : type === "download blue"
      ? "auto"
      : type === "download red"
      ? "auto"
      : "129px"};
  height: ${({ type }) =>
    type === "white"
      ? "51px"
      : type === "writer"
      ? "51px"
      : type === "white-empty"
      ? "51px"
      : type === "empty"
      ? "auto"
      : "42px"};
  background: ${({ type }) =>
    type === "primary"
      ? "#e44331"
      : type === "empty"
      ? "transparent"
      : type === "white"
      ? "#ffffff"
      : type === "writer"
      ? "#ffffff"
      : type === "white-empty"
      ? "transparent"
      : type === "blue"
      ? " #3571CE"
      : type === "blue-empty"
      ? "transparent"
      : type === "download blue"
      ? "#3571CE"
      : type === "download red"
      ? "#2c6491"
      : "#e44331"};
  border: ${({ type }) =>
    type === "primary"
      ? "#e44331"
      : type === "empty"
      ? "1px solid #2c6491"
      : type === "white"
      ? "1px solid #ffffff"
      : type === "white-empty"
      ? "1px solid #ffffff"
      : type === "white"
      ? "1px solid #ffffff"
      : type === "blue"
      ? "1px solid #3571CE"
      : type === "blue-empty"
      ? "1px solid #3571CE"
      : "#e44331"};
  border-radius: 60px;
  flex: none;

  //universal
  cursor: pointer;

  &:hover {
    background: ${({ type }) =>
      type === "primary"
        ? "rgba(228, 67, 49, 0.8)"
        : type === "empty"
        ? "#efefef"
        : type === "white"
        ? "#efefef"
        : type === "writer"
        ? "#efefef"
        : type === "blue"
        ? "rgba(53, 113, 206,0.8)"
        : type === "blue-empty"
        ? "rgba(53, 113, 206,0.2)"
        : type === "white-empty"
        ? "transparent"
        : type === "download blue"
        ? "rgba(53, 113, 206,0.8)"
        : type === "download red"
        ? "#d40000"
        : "rgba(228, 67, 49, 0.8)"};
    border: ${({ type }) =>
      type === "primary"
        ? "rgba(228, 67, 49, 0.8)"
        : type === "empty"
        ? "1px solid #2c6491"
        : type === "white"
        ? "1px solid #efefef"
        : type === "white-empty"
        ? "1px solid #efefef"
        : type === "writer"
        ? "1px solid #efefef"
        : type === "blue"
        ? "1px solid rgba(53, 113, 206,0.8)"
        : type === "blue-empty"
        ? "1px solid rgba(53, 113, 206,0.8)"
        : "rgba(228, 67, 49, 0.8)"};

    .text {
      font-weight: ${({ type }) =>
        type === "white-empty" ? "bold" : "normal"};
    }
  }

  @media (max-width: 768px) {
    width: ${({ type, width }) =>
      type === "empty"
        ? "268px"
        : type === "white"
        ? "317px"
        : type === "white-empty"
        ? "317px"
        : type === "220px"
        ? "317px"
        : width !== ""
        ? "216px"
        : type === "blue"
        ? "147px"
        : type === "download"
        ? "280px"
        : "320px"};
    height: ${({ type }) =>
      type === "white"
        ? "51px"
        : type === "white-empty"
        ? "51px"
        : type === "white-empty"
        ? "51x"
        : "42px"};
    ${({ type }) => (type === "empty" ? "width: auto" : "")};
    ${({ type }) => (type === "empty" ? "height: auto" : "")};
  }

  @media (max-width: 475px) {
    width: ${({ width, type }) =>
      width !== ""
        ? "216px"
        : type === "blue"
        ? "147px"
        : type === "primary"
        ? "250px"
        : type === "primary special"
        ? "250px"
        : type === "white"
        ? "300px"
        : type === "download"
        ? "280px"
        : "320px"};
    ${({ type }) => (type === "empty" ? "width: auto;" : "")};
  }

  ${({ type }) => (type === "empty" ? "width: auto" : "")};
`;

const ButtonText = styled.span`
  width: ${({ type }) =>
    type === "empty" ? "260px" : type === "white" ? "300px" : "max-content"};
  height: ${({ type }) => (type === "empty" ? "auto" : "16px")};
  text-align: center;
  font-style: normal;
  font-weight: ${({ type }) =>
    type === "white" ? "bold" : type === "writer" ? "bold" : "500"};
  font-size: ${({ type }) => (type === `download` ? `14px` : `16px`)};
  line-height: ${({ type }) => (type === "white" ? "21px" : "16px")};
  letter-spacing: ${({ type }) => (type === "empty" ? "0.05em" : "0.1em")};
  text-transform: ${({ type }) => (type === "empty" ? "initial" : "uppercase")};
  color: ${({ type }) =>
    type === "primary"
      ? "#ffffff"
      : type === "empty"
      ? "#2c6491"
      : type === "white"
      ? "#111111"
      : type === "writer"
      ? "#111111"
      : type === "white-empty"
      ? "#ffffff"
      : type === "blue-empty"
      ? "#3571CE"
      : "#ffffff"};
  flex: none;
  flex-grow: 0;

  &:hover {
    font-weight: ${({ type }) => (type === "blue-empty" ? "bold" : "normal")};
  }
  @media (max-width: 475px) {
    width: ${({ width }) => (width !== "" ? "216px" : "327px")};
    height: max-content;
    ${({ type }) => (type === "empty" ? "width: 100%" : "")};
    /* ${({ type }) => (type === "empty" ? "margin: 16px 0" : "")}; */
  }

  ${({ type }) => (type === "empty" ? "width: 100%" : "")};
  /* ${({ type }) => (type === "empty" ? "margin: 16px 0" : "")}; */
`;

function Buttons({
  title,
  navigation,
  type,
  width,
  tracker,
  action,
  trackEvent,
  onClick,
}) {
  // const { urlParameter } = useContext(GlobalContext);
  // const queryString =
  //   typeof window !== "undefined" ? window.location.search : "";
  // const url = typeof window !== "undefined" ? window.location.pathname : "";

  // function addParametersToUrl() {
  //   if (urlParameter !== "" && queryString.length === 0) {
  //     window.history.pushState(
  //       { path: url + "?" + urlParameter },
  //       "",
  //       url + "?" + urlParameter
  //     );
  //   }
  // }

  const hrefProp = navigation ? { href: navigation } : {};

  return (
    <>
      {action === "outbounds" ? (
        <PrimaryButton
          type={type}
          width={width}
          className={tracker}
          as="a"
          style={{ textDecoration: `none` }}
          href={navigation}
        >
          <ButtonText type={type} width={width} className="text">
            {title}
          </ButtonText>
        </PrimaryButton>
      ) : action === "tracking" ? (
        <PrimaryButton
          type={type}
          width={width}
          className={tracker}
          as="a"
          id="buy"
          style={{ textDecoration: `none` }}
          href={navigation}
          onClick={() => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "buyButtonClick",
              category: "download",
              action: trackEvent.action || "",
              label: trackEvent.label || "",
            });
          }}
        >
          <ButtonText type={type} width={width} className="text">
            {title}
          </ButtonText>
        </PrimaryButton>
      ) : (
        <PrimaryButton
          type={type}
          width={width}
          className={tracker}
          as="a"
          style={{ textDecoration: `none` }}
          {...hrefProp}
          onClick={onClick}
        >
          <ButtonText type={type} width={width} className="text">
            {title}
          </ButtonText>
        </PrimaryButton>
      )}
    </>
  );
}

Buttons.propTypes = {
  title: PropTypes.string,
  navigation: PropTypes.string.isRequired,
  type: PropTypes.string,
  width: PropTypes.string,
  tracker: PropTypes.string,
};

Buttons.defaultProps = {
  title: "",
  navigation: "/",
  type: "primary",
  width: "",
  tracker: "",
};

export default Buttons;
