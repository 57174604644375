import React from "react";
import Seo from "../components/shared/seo";
import Hero from "../components/resourcesPage/hero";
import { useEffect } from "react";
import { navigate } from "gatsby";
import Layout from "../components/shared/layout";

function Resources() {
  useEffect(() => {
    navigate("/blog/");
  }, []);

  return (
    <Layout>
      <Seo title="Resources Page" />
      <Hero />
    </Layout>
  );
}

export default Resources;
